/* src/index.css */

@font-face {
  font-family: 'MonclerGothic';
  src: url('./assets/fonts/MonclerGothic-Thin.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'MonclerGothic';
  src: url('./assets/fonts/MonclerGothic-ExtraLight.otf') format('opentype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'MonclerGothic';
  src: url('./assets/fonts/MonclerGothic-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'MonclerGothic';
  src: url('./assets/fonts/MonclerGothic-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'MonclerGothic';
  src: url('./assets/fonts/MonclerGothic-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'MonclerGothic';
  src: url('./assets/fonts/MonclerGothic-SemiBold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'MonclerGothic';
  src: url('./assets/fonts/MonclerGothic-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}


body {
  margin: 0;
  font-family: 'MonclerGothic', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
