.contentContainer {
    padding-left: 48px;
}

.formContainer {
    width: 50%;
}

.buttonContainer {
    width: 95vw;
}

.welcomeText {
    width: 90%;
}

.logo {
    width: 10%;
}

.logoSuccess {
    width: 15%;
}



@media only screen and (max-width: 768px) {
    .contentContainer {
        padding-left: 24px;
    }

    .formContainer {
        width: 85%;
    }

    .buttonContainer {
        width: 88vw;
    }

    .logo {
        width: 30%;
    }

    .logoSuccess {
        width: 50%;
    }

    .image {
        opacity: 0;
    }

}